@import url(https://fonts.googleapis.com/css?family=Montserrat);
/* 
html, body{
  height: 100%;
  font-weight: 800;
}

body{
  background: yellow;
  font-family: Arial;
}
 */
svg.logo {
    display: block;
    font: 10.5em 'Montserrat';
    /* width: 960px;
    height: 300px; */
    width: 100%;
    height: 100%;
    margin: 0 auto;
}

.text-copy {
    fill: none;
    stroke: white;
    stroke-dasharray: 6% 29%;
    stroke-width: 5px;
    stroke-dashoffset: 0%;
    animation: stroke-offset 5.5s infinite linear;
}

.text-copy:nth-child(1){
	stroke: #4D163D;
	animation-delay: -1;
}

.text-copy:nth-child(2){
	stroke: #840037;
	animation-delay: -2s;
}

.text-copy:nth-child(3){
	stroke: #BD0034;
	animation-delay: -3s;
}

.text-copy:nth-child(4){
	stroke: #BD0034;
	animation-delay: -4s;
}

.text-copy:nth-child(5){
	stroke: #FDB731;
	animation-delay: -5s;
}

@keyframes stroke-offset{
	100% {stroke-dashoffset: -35%;}
}
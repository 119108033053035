%common-bg-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat
}

.main-page {
    @extend %common-bg-image;
    .webp & {
        background-image: url('../asset/img/background.webp');
    }
    .no-webp & {
        background-image: url('../asset/img/background.jpg');
    }
}

.category-page { 
    @extend %common-bg-image;
    .webp & {
        background-image: url('../asset/img/cat-bg.webp');
    }
    .no-webp & {
        background-image: url('../asset/img/cat-bg.jpg');
    }
}

.play-page { 
    @extend %common-bg-image;
    .webp & {
        background-image: url('../asset/img/play-bg.webp');
    }
    .no-webp & {
        background-image: url('../asset/img/play-bg.jpg');
    }
}
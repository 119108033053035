.hg-theme-default {
    .hg-button {
        &.disable-color {
            background-color: lightgrey
        }

        &.disable-events {
            pointer-events: none;
        }
        
    }
}

.something {
    display: grid;
  svg {
    grid-area: 1 / 1;
  }
}
// Override default variables before the import
//$body-bg: #000;
$font-family-base: 'Bangers', cursive;//'Changa', sans-serif;
$headings-font-family:  'Bangers', cursive;//'Shadows Into Light', cursive;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
/* @import url('https://fonts.googleapis.com/css2?family=Changa:wght@200;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Bangers&display=swap');
@import url('https://fonts.googleapis.com/css?family=Carter+One'); */

html,
body {
    overscroll-behavior-y: contain;
}

.font-normal {
    font-display: swap;
    font-family: 'Carter One', sans-serif;
}

.fs-7 {
    font-size: 0.7rem;
}

.fancy-style {
    -moz-background-clip: text;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: url(./asset/img/giphy.webp);
    background-size: cover;
    color: transparent;
    letter-spacing: 1px;
    text-transform: uppercase;
}